
.theory-train-detail {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    background-color: #F2F3FA;
    padding: 20px 21px 17px 21px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .topics-list {
        flex: 1;
        height: 100%;
        margin-right: 20px;
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .theory-paper-name {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                color: #343441;
                font-size: 16px;
            }
        }
    }
    .topic-process-box {
        height: 100%;
        width: 370px;
    }
    .topic-process {
        position: fixed;
        top: 80px;
        right: 21px;
        height: calc(100vh - 100px);
        width: 370px;
        .topic-btn-group {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            &::v-deep .topic-btn{
                flex: 1;
            }
        }
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
}
.topic-list-ul {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    .topic-item-arr {
        margin-top: 5px;
        .topic-header {
            width: 100%;
            background-color: #FFF;
            height: 57px;
            line-height: 57px;
            padding-left: 29px;
            font-weight: bold;
        }
    }
}

