
.stu-avatar-info {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .avatar {
        margin-right: 13px;
    }
    .username {
        color: #333333;
        font-size: 16px;
        margin-right: 15px;
    }
    .to-index {
        cursor: pointer;
        i.iconfont {
            font-size: 20px;
            color: #AAA;
        }
    }
}
