
.theory-competition {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    .theory-header {
        position: relative;
        width: 100%;
        height: 60px;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        .time-countdown-content {
            i.iconfont {
                font-size: 18px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #E21D1A;
        }
        .user-content {
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
        }
    }
    .theory-competition-body {
        width: 100%;
        height: calc(100vh - 60px);
        background-color: #F2F3FA;
    }
}

